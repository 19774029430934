import Vue from 'vue'
import App from './App.vue'
import store from './store'
import 'bootstrap'
import VueGtag from "vue-gtag";

import VueRouter from 'vue-router'
import fetchRoutes from './router'

var prova = []
var customRoutes = {
  "path": "/students/:nid",
  "name": "SingleStudent"
}


Vue.use(VueRouter)

fetchRoutes.then(json => {

  prova = json.data.routes
  prova.push(customRoutes)

  //console.log('json.data.routes',json.data.routes);

  prova.forEach(p => {
    p.component = () => import(/* webpackChunkName: "" */ './views/'+p.name+'.vue')
  })
  
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: prova,
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
  })

  Vue.use(VueGtag, {
    config: { id: "G-BZGQ9YX2BZ" }
  },router);

  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app")

})