<template>
  <div class="footer-wrapper">
    <back-to-top></back-to-top>
    <div class="container">
      <div class="row mt-4">
        <div class="col-lg-4 first-col">
          <div class="logo-footer-container">
            <img class="logo-footer" src="@/assets/logo-simple.svg" alt="logo" />
            <img class="logo-footer-brand" src="@/assets/logo-text-white.png" alt="logo" />
          </div>
          <div class="address">
            SISSA - Scuola Internazionale Superiore di Studi Avanzati | Via Bonomea 265 - 34136 Trieste - ITALY
            <br><br>
            ICTP - International Centre for Theoretical Physics | Strada Costiera 11 - 34151 Trieste - ITALY
          </div>
        </div>
        <div class="col-lg-8 d-none d-lg-flex link-sections-container" v-if="this.linkItems">
          <div class="link-section-footer">
            <div class="link-title">{{ this.linkItems[0].label }}</div>
            <ul>
              <li v-for="(link, index) of this.linkItems[0].children" :key="index">
                <router-link :to="link.url">{{ link.label }}</router-link>
              </li>
            </ul>
          </div>
          <div class="link-section-footer">
            <div class="link-title">{{ this.linkItems[1].label }}</div>
            <ul>
              <li v-for="(link, index) of this.linkItems[1].children" :key="index">
                <router-link :to="link.url">{{ link.label }}</router-link>
              </li>
            </ul>
          </div>
          <div class="link-section-footer">
            <div class="link-title">{{ this.linkItems[2].label }}</div>
            <ul>
              <li v-for="(link, index) of this.linkItems[2].children" :key="index">
                <router-link :to="link.url">{{ link.label }}</router-link>
              </li>
            </ul>
            <div class="link-title mt-4">
              <router-link to="/contact-us">{{ this.linkItems[3].label }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="partnerships pt-4">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="pb-2">Organized by:</div>
            <div class="align-items-center pb-4">
              <div class="py-2 d-flex align-items-center">
                <img class="mr-4 img-fluid logo-sissa" src="@/assets/logo-sissa.png" alt="sissa" width="50" />
                <img class="img-fluid logo-ictp" src="@/assets/logo-ictp.png" alt="ictp" width="178" />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="row align-items-center mb-4">
              <div class="col-6 col-lg-12 pb-2">In collaboration with:</div>
              <div class="col-6 col-lg-12">
                <img width="160" class="img-fluid logo-friuli" src="@/assets/logo-friuli.png"
                  alt="regione autonoma friuli venezia giulia" />
              </div>
            </div>
          </div>
          <div class="col-lg-3 ">
            <router-link class="cta-outline-light mr-3 mb-4" to="/for-students">For Students</router-link>
            <router-link class="cta-outline-light" to="/organizations">For Organizations</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid spacer"></div>
    <div class="container copyright">
      <div class="row">
        <div class="copyright-text">
          © Copyright 2021 | CF 80035060328 - TEL: (+39)0403787111 - INFO:
          info@sissa.it PEC: protocollo@pec.sissa.it
          <router-link to="/cookie-policy">Cookie Policy</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
        </div>
        <div class="copyright-social"><social-links></social-links></div>
      </div>
    </div>
  </div>
</template>
<script>
import SocialLinks from "./SocialLinks.vue";
import BackToTop from './BackToTop.vue'

export default {
  components: {
    SocialLinks,
    BackToTop
  },
  data() {
    return {
      linkItems: null,
    };
  },
  methods: {
    async fetchLinkItems() {
      //console.log('sdf');
      var menu = []
      var items = []
      var ch = []

      await fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/menu_items/front-menu`)
        .then(res => res.json())
        .then(json => items = json.data)


      items.forEach(item => {
        ch = []

        if (item.attributes.parent === "") {
          if (items.filter(it => it.attributes.parent === item.id).length == 0) {
            menu.push({
              label: item.attributes.title,
              url: item.attributes.url
            })

          } else {
            items.filter(it => it.attributes.parent === item.id)
              .forEach(child => ch.push({
                label: child.attributes.title,
                url: child.attributes.url
              }))


            menu.push({
              label: item.attributes.title,
              children: ch
            })
          }
        }
      })

      this.linkItems = menu

    },
  },
  mounted() {
    this.fetchLinkItems();
  }
};
</script>
<style lang="scss" scoped>
.partnerships {
  font-size: 13px;
  line-height: 20px;
  color: #999;
}

.address {
  padding-top: 30px;
  font-size: 13px;
  line-height: 20px;
  color: #999;
}

.container-fluid.spacer {
  border-top: 1px solid #343a44;
}

.copyright {
  padding-top: 25px;
  font-size: 13px;
  line-height: 20px;
  color: #999;

  .row {
    align-items: center;
    justify-content: space-between;
  }
}

.copyright-social {
  display: flex;
  justify-content: flex-end;
  width: 280px;
}

.logo-footer-brand {
  width: 175px;
  margin-left: 10px;
  max-width: calc(50% - 10px);
}

.logo-footer {
  width: 150px;
  max-width: 50%;
}

.logo-footer-container {
  display: flex;
  align-items: center;
}

.footer-wrapper {
  position: relative;
  padding-top: 30px;
  padding-bottom: 25px;
  background: #1c232f;
}

.link-sections-container {
  display: flex;
  font-size: 15px;
  line-height: 28px;
}

.link-section-footer {
  display: inline-block;
  width: 33%;
  padding: 30px 25px;
  margin-left: 10px;
  border-left: 1px solid #343a44;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: block;
    }
  }

  .link-title a,
  .link-title {
    color: #1dc07e;
  }

  a {
    color: #fff;
  }
}

.first-col {
  border-bottom: 1px solid #343a44;
  padding-bottom: 30px;
}

.copyright-text a {
  color: #fff;
  display: inline-block;
  padding-left: 10px;
}

@media (min-width: 992px) {

  .logo-footer-brand,
  .logo-footer {
    width: 80%;
    margin: 0;
    max-width: none;
  }

  .logo-footer-container {
    flex-wrap: wrap;
  }

  .logo-sissa {
    width: 93px;
  }

  .logo-ictp {
    width: 300px;
  }

  .logo-friuli {
    width: 200px;
    margin: 50px auto;
  }

  .first-col {
    border: 0;
  }
}


@media (max-width: 992px) {
  .back-top-top {
    display: none;
  }

  .footer-wrapper {
    margin-top: 0rem;
  }
}

@media (max-width: 576px) {
  .copyright-social {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 22px;
  }
}
</style>