import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=1a44cbaf&scoped=true&"
var script = {}
import style0 from "./SocialLinks.vue?vue&type=style&index=0&id=1a44cbaf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a44cbaf",
  null
  
)

export default component.exports