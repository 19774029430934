<template>
  <nav class="navbar navbar-expand-lg">
    <router-link to="/" class="navbar-brand" @click.native="closeMenu()">
      <img
        src="@/assets/logo-desktop.png"
        alt="logo"
        class="logo d-none d-lg-block"
      />
      <img
        src="@/assets/logo-simple.svg"
        alt="logo"
        class="logo-mob d-lg-none"
      />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      @click="toggleMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <img src="@/assets/hamburger.svg" alt="menu" class="hamburger" />
    </button>

    <div
      :class="[
        'collapse',
        'navbar-collapse',
        'navbar-container',
        menuOpen ? 'show' : '',
      ]"
    >
      <ul class="navbar-nav main-nav">
        <li class="spacer"></li>
        <li
          :class="['nav-item', index === submenuOpen ? 'active' : '']"
          v-for="(item, index) of menuItems"
          :key="index"
        >
          <div class="nav-item-content" v-if="item.children && item.children.length > 0" @click="openSubmenu(index)">
              <span class="nav-link">{{ item.label }}</span>
              <span class="chevron-submenu"><img src="@/assets/chevron-green.svg" alt="open submenu"/></span>
          </div>
          <div v-else>
            <router-link
              class="nav-link"
              :to="item.url"
              @click.native="closeMenu"
              >{{ item.label }}</router-link
            >
          </div>
          <ul
            v-if="item.children && item.children.length > 0"
            :class="['submenu', submenuOpen === index ? 'active': '']"
          >
            <li v-for="(child, index) of item.children" :key="index">
              <router-link :to="child.url" @click.native="closeMenu">{{
                child.label
              }}</router-link>
            </li>
          </ul>
        </li>
        <li class="spacer"></li>
      </ul>
     <ul class="navbar-nav">
        <li>
          <router-link
            @click.native="closeMenu"
            class="cta cta-primary mx-lg-4"
            to="/for-students"
            >For Students</router-link
          >
        </li>
        <li>
          <router-link
            @click.native="closeMenu"
            class="cta cta-secondary"
            to="/organizations"
            >For Organizations</router-link
          >
        </li>
      </ul> 
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      menuOpen: false,
      submenuOpen: null,
      menuItems: [],
    };
  },
  methods: {
    async fetchMenuItems() {
      var menu = []
      var items = []
      var ch = []

      await fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/menu_items/front-menu`)
        .then(res => res.json())
        .then(json => items = json.data)


      items.forEach(item => {
        ch = []

        if(item.attributes.parent === "") {
          if(items.filter(it => it.attributes.parent === item.id).length==0) {
            menu.push({
              label: item.attributes.title,
              url: item.attributes.url
            })

          } else {
            items.filter(it => it.attributes.parent === item.id)
              .forEach(child => ch.push({
                label: child.attributes.title,
                url: child.attributes.url
              }))


            menu.push({
              label: item.attributes.title,
              children: ch
            })
          }
        }
      })

      this.menuItems = menu

    },
    toggleMenu() {
      this.menuOpen ? this.closeMenu() : this.openMenu();
    },
    openMenu() {
      document.querySelector("html").classList.add("menu-open");
      document.body.classList.add("menu-open");
      this.menuOpen = true;
    },
    closeMenu() {
      document.querySelector("html").classList.remove("menu-open");
      document.body.classList.remove("menu-open");
      this.menuOpen = false;
    },
    openSubmenu(index) {
      if (this.submenuOpen === index) {
        this.submenuOpen = null;
      } else {
        this.submenuOpen = index;
      }
    },
  },
  created() {
    this.fetchMenuItems();
  }
};
</script>
<style lang="scss" scoped>
.navbar {
  background-color: rgba(255, 255, 255, 0.9);
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid #e6e6e6;
}
.navbar-container {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  overflow: auto;
  top: 56px;
  background: #fff;
  height: calc(100% - 56px);
  z-index: 1000;
  width: 100%;
  left: 0;
  padding: 20px;
  animation: slide-left 500ms;
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.nav-item {
  margin: 10px 0;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  &.active {
    .nav-link {
      color: #1dc07e;
    }
  }
}
.nav-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chevron-submenu {
  padding: 10px 30px;
  cursor: pointer;
}
.main-nav {
  flex: 1;
  justify-content: space-around;
  .spacer {
    border-left: 1px solid #ebebeb;
    height: 100%;
    display: none;
  }
}

.logo {
  max-width: 355px;
  min-width: 150px;
  width: 100%;
  height: auto;
}
.logo-mob {
  width: 120px;
  padding: 0px 20px;
}

.navbar-nav {
  .nav-link {
    font-size: 20px;
    color: #666666;
    font-weight: 500;
    width: 100%;
    padding: 12px 10px;
    white-space: nowrap;
  }
  .cta {
    width: 100%;
    margin: 10px 0;
  }
}
.submenu {
  display: none;
  @media (max-width:992px) {
    &.active{
      display: block;
    }
  }
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    a {
      display: block;
      padding: 15px 22px;
      color: #213245;
    }
    &:last-child {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: 992px) {
  .main-nav {
    align-items: center;
    .spacer {
      display: block;
    }
  }
  .navbar-container {
    position: static;
    overflow: initial;
    animation: none;
  }
  .main-nav {
    height: 80px;
    margin: -10px 0;
  }
  .nav-item {
    position: relative;
    padding: 0 10px;
    border: 0;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    .nav-link {
      font-size: 16px;
    }
    @keyframes submenu {
      0% {
        transform: scaleX(0.5) scaleY(0.5);
      }
      100% {
        transform: scaleX(1) scaleY(1);
      }
    }
    .submenu {
      transform-origin: top center;
      position: absolute;
      background: #fff;
      width: 250px;
      padding: 20px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      box-shadow: 1px 7px 15px #0000000d;
    }
    &:hover {
      .submenu {
        animation: submenu 200ms;

        display: block !important;
        left: -20px;
        top: 100%;
      }
      .nav-link {
        color: #213245;
      }
    }
    .chevron-submenu {
      display: none;
    }
  }
  .navbar-nav {
    .cta {
      padding: 10px 20px;
      width: auto;
      white-space: nowrap;
    }
  }
  .navbar-container {
    padding: 0;
    background-color: transparent;
  }
}
@media (min-width: 1200px) {
  .navbar-container {
    padding-right: 20px;
  }
  .logo {
    padding-left: 20px;
  }
}
</style>