//import Vue from 'vue'
//import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

//Vue.use(VueRouter)

async function fetchRoutes() {
 
  return await fetch(`${process.env.VUE_APP_ENDPOINT}/json/front-routes`)
    .then(res => res.json())
}

export default fetchRoutes()
