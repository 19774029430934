import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    people: [],
    currentAcademicYear: null
  },
  mutations: {
    SET_PEOPLE: function(state, payload) {
      state.people = payload;
    },
    SET_CURRENT_ACADEMIC_YEAR: function(state, payload){
      state.currentAcademicYear = payload
    }
  },
  actions: {
    LOAD_PEOPLE: function({ commit, state }) {
      if (state.people.length === 0) {
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/person`)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            commit("SET_PEOPLE", json.data);
          });
      }
    },
  },
  modules: {},
});
