<template>
    <div class="back-top-top" @click="backToTop()">
        <div class="container">
            <p></p>
        </div>
    </div>
</template>
<script>
export default {
    name:"back-top-top",
  data() {
    return {
    };
  },
  methods: {
    backToTop() {
      window.scrollTo(0,0)
    },
  },
};
</script>
<style lang="scss" scoped>
.back-top-top {
    .container {
        display: flex;
        justify-content: flex-end; 
        p {
    cursor: pointer;
    position: absolute;
    width: 80px;
    height: 80px;
    top: -40px;
    z-index: 10;
    display: flex;
    border: 1px solid #1DC07E;
    align-items: flex-end;
    color: #1DC07E;
    border-radius: 150px;
    justify-content: center;
    padding-bottom: 0;
    background: #1DC07E;
    color: #fff;
    margin: 0;
            &:before{
    width: 22px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 22px;
    background: url("../assets/btt-arrow-01.svg");
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0);
            }
        }
    }
}
</style>