<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>
<script>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'

export default {
  components: { TheHeader, TheFooter },
  methods:{
    setCurrentAcademicYear(){
      const today = new Date();
      let currMonth = today.getMonth() // gennaio = 0
      let currYear = today.getFullYear() 
      let academicYear;
      
      // 8 = settembre
      // il primo di settembre scatta l'anno successivo
      if (currMonth < 8) { 
        currYear = currYear - 1;
      }

      let nextYear = (currYear + 1 ).toString()
      academicYear = currYear.toString() + ' - ' + nextYear; 
      this.$store.commit('SET_CURRENT_ACADEMIC_YEAR',academicYear);
    }
  },
  mounted(){
    this.setCurrentAcademicYear()
  }
}
</script>
<style lang="scss">
@import "common.scss";

#app {
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app > div:not(.footer-wrapper) {
  min-height: 80vh;
}
</style>
